import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import Figure from '~components/Figure'

const ImageModule = ({ className, image, isMobile }) => {
	const ratio = image.image?.asset?.metadata?.dimensions?.aspectRatio
	
	const gridColumn = ()=> {
		if(image.width === 'fullWidth') {
			return '1/13'
		} else if (ratio < 1){
			return '5/9'
		} else {
			return '3/11'
		}
	}

	return (
		<Wrap className={className}>
			<Section 
				css={css`
				margin-top: 150px; 
				margin-bottom: 150px; 
				text-align: center;
				${mobile}{
					margin-top: 80px;
					margin-bottom: 80px;
				}
			`}>
				<Figure 
					content={image.image}
					aspectRatio={(isMobile && image.width === 'fullWidth') ? 1 : null} 
					css={css` 
						grid-column: ${gridColumn()};
						margin: ${image.width === 'fullWidth' ? '0 -40px' : '0'};
						${mobile}{
							margin: ${image.width === 'fullWidth' ? '0 -20px' : '0'};
							grid-column: ${image.width === 'fullWidth' ? '1/7' : '1/7'};
						}
					`}
				/>
			</Section>
		</Wrap>
	)
}

const Wrap = styled.div`
	
`

ImageModule.propTypes = {
	className: PropTypes.string,
	image: PropTypes.object,
	isMobile: PropTypes.object,
}

export default ImageModule