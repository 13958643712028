import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import useNavData from '~utils/useNavData'
import SanityLink from './SanityLink'
import Button from './Button'
import TileArticle from './TileArticle'
import MenuStore from './MenuStore'
import { PlusCircle } from './Svg'
import { useSiteState } from '../context/siteContext'
import FadeTransition from '~components/FadeTransition'

const Menu = ({ className, view }) => {
	const [siteState, setSiteState] = useSiteState()
	const navData = useNavData()
	return (
		<Wrap 
			className={className} 
			css={css`${mobile}{
			margin-top: ${siteState.menuOpen ? '94px' : '46px'};
		}`}>
			<FadeTransition integer={view} duration={siteState.menuOpen ? 0.6 : 0}>
				{view === 'store' && 
					<>
						<Heading>{navData.storeHeading}</Heading>
						<MenuStore products={navData.storeItems}/>
					</>
				}
				{view === 'about' && 
					<>
						<Heading>{navData.aboutHeading}</Heading>
						<AboutContent>
							{navData.aboutPages.map(page => (
								<AboutTile key={page?.title}>
									<AboutTitle link={{ 								
										linkType: 'internal', 
										document: {
											slug: page?.slug,
										}
									}}>
										<h1>{page?.title}</h1>
									</AboutTitle>
									<AboutImage link={{ 								
										linkType: 'internal', 
										document: {
											slug: page?.slug,
										}
									}}>
										<Image 
											image={page?.content?.headerImage} 
											aspectRatio={1.48}
										/>
									</AboutImage>
									<Button 
										className='h3' 
										link={{ 								
											linkType: 'internal', 
											document: {
												slug: page?.slug,
											}
										}}>
											Discover
									</Button>
								</AboutTile>
							))}
						</AboutContent>
					</>
				}
				{view === 'journal' && 
					<>
						<Heading>{navData.journalHeading}</Heading>
						<JournalContent>
							{navData.featuredArticles.map(article => (
								<ArticleTile key={article.title} article={article}/>
							))}
						</JournalContent>
					</>
				}
				<BaseLine>
					<Button 
						className='h3'
						link={{
							linkType: 'internal',
							document: {
								slug: {
									current: `${view === 'about' ? 'brand' : view}`
								}
							}
						}}>
						{view === 'store' && 'Full Range'}
						{view === 'about' && 'About'}
						{view === 'journal' && 'All articles'}
					</Button>
					<Close 
						className='h3'
						onClick={()=> setSiteState(prevState => ({
							...prevState,
							menuOpen: false,
							mobileNavOpen: false
						}))}
					>
					Close <Cross/>
					</Close> 
				</BaseLine>
			</FadeTransition>
		</Wrap>
	)
}

const Wrap = styled.div`
	margin-top: 55px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: var(--beige);
	z-index: 1;
	overflow-y: scroll;
	max-height: calc(100vh - 55px);
	transition: margin-top 0.8s, transform 0.8s;
	&::-webkit-scrollbar {
		display: none;
	}
	scrollbar-width: none;
	-ms-overflow-style: none;
	${mobile}{
		max-height: calc(100vh - 94px);
	}
`
const Heading = styled.h2`
	padding: var(--l) 40px;
	text-align: center;
	border-bottom: 1px solid var(--black);
	${mobile}{
		padding: var(--l) 20px;
	}
`
const AboutContent = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	border-bottom: 1px solid var(--black);
`
const AboutTile = styled.div`
	padding: var(--xxxl) var(--m);
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	&:first-of-type{
		border-right: 1px solid var(--black);
	}
	${mobile}{
		padding: var(--l) var(--m);
	}
`
const AboutTitle = styled(SanityLink)`
	margin-bottom: var(--xl);
	display: block;
	${mobile}{
		margin-bottom: var(--l);
	}
`
const AboutImage = styled(SanityLink)`
	margin-bottom: var(--xl);
	overflow: hidden;
	display: block;
	max-width: 420px;
	width: 100%;
	img{
		transform: scale3d(1, 1, 1);
		transition: transform 0.3s;
	}
	&:hover{
		img{
			transform: scale3d(1.02, 1.02, 1);
		}
	}
	${mobile}{
		margin-bottom: var(--l);
	}
`
const JournalContent = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	border-bottom: 1px solid var(--black);
	${mobile}{
		grid-template-columns: repeat(2, 1fr);
	}
`
const ArticleTile = styled(TileArticle)`
	border-right: 1px solid var(--black);
	&:last-of-type{
		border-right: none;
	}
	${mobile}{
		&:nth-of-type(2){
			border-right: none;
		}
		&:nth-of-type(3){
			display: none;
		}
	}
`
const BaseLine = styled.div`
	border-bottom: 1px solid var(--black);
	display: flex;
	justify-content: space-between;
	padding: var(--s) var(--l);
	align-items: center;
	${mobile}{
		padding: var(--s) var(--m);
	}
`
const Close = styled.button`
	justify-self: end;
`
const Cross = styled(PlusCircle)`
	width: 13px;
	height: 13px;
	transform: rotate(45deg);
	position: relative;
	top: 3px;
`
Menu.propTypes = {
	className: PropTypes.string,
	view: PropTypes.string,
}


export default Menu