import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import resolveLink from '~utils/resolveLink'

const SanityLink = ({ className, link, children, to }) => {
	if (!link && !to){
		return(
			<div className={className}>{children}</div>
		)
	} else if((link?.linkType === 'internal') || to){
		return (
			<Link className={className} to={to ?? resolveLink(link?.document)}>
				{children}
			</Link>
		)
	} else{
		return (
			<a 
				href={link?.url} 
				target={link?.blank ? '_blank' : '_self'} 	
				rel='noreferrer'
				className={className}
			>
				{children}
			</a>
		)
	}
}

SanityLink.propTypes = {
	className: PropTypes.string,
	link: PropTypes.object,
	children: PropTypes.node,
	to: PropTypes.string,
}

export default SanityLink