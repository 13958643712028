import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile } from '~styles/global'
import Image from '~components/Image'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'

const TileArticle = ({ className, article }) => {
	const [hover, setHover] = useState(false)

	return (
		<Wrap 
			to={resolveLink(article)} 
			className={className}
			onMouseEnter={()=> setHover(true)}
			onMouseLeave={()=> setHover(false)}
		>	
			<ImageContainer>
				<Thumb 
					image={article?.content?.thumb} 
					aspectRatio={1}
					css={css`img{transform: ${hover ? 'scale3d(1.02, 1.02, 1)' : 'scale3d(1, 1, 1)'};}`}
				/>
			</ImageContainer>
			<Meta>
				<span>
					{article?.content?.topics?.map(topic => (
						topic.title
					)).join(', ')}
				</span> ● <span>{article?.datePublished}</span>
			</Meta>
			<Excerpt className='h2'>{article?.title}</Excerpt>
		</Wrap>
	)
}

const Wrap = styled(Link)`
	padding: var(--m);
	padding-bottom: var(--l);
`
const ImageContainer = styled.div`
	overflow: hidden;
	margin-bottom: var(--l);
`
const Thumb = styled(Image)`
	img{
		transition: transform 0.3s;
	}
`
const Meta = styled.h3`
	margin-bottom: var(--l);
	text-align: center;
`
const Excerpt = styled.p`
	text-align: center;
`

TileArticle.propTypes = {
	className: PropTypes.string,
	article: PropTypes.object,
}

export default TileArticle