import React, { useState, useLayoutEffect, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import useBreakpoint from '~utils/useBreakpoint'
import { Global } from '@emotion/react'
import { motion } from 'framer-motion'
import { isMobile } from 'react-device-detect'

const Cursor = ({ className }) => {

	const [showMouse, setShowMouse] = useState(false)
	const [mouseX, setMouseX] = useState(false)
	const [mouseY, setMouseY] = useState(false)

	useLayoutEffect(() => {
		window.addEventListener('mousemove', e => {
			setShowMouse(true)
			setMouseX(e.clientX)
			setMouseY(e.clientY)
		})
		return window.removeEventListener('mousemove', e => {
			setShowMouse(true)
			setMouseX(e.clientX)
			setMouseY(e.clientY)
		})
	}, [])

	// useLayoutEffect(() => {
	// 	window.addEventListener('mouseout', e => {
	// 		setShowMouse(false)
	// 	})
	// 	return window.addEventListener('mouseout', e => {
	// 		setShowMouse(false)
	// 	})
	// }, [])

	// return null

	if(isMobile) return null
	
	return (
		<>
			<Global styles={css`
				html, html * {
					cursor: none !important;
				}
			`} />
			<Wrap 
				className={className}
				initial={{ x: - 10, y: -10 }}
				animate={{
					x: mouseX - 10,
					y: mouseY - 10,
					opacity: showMouse ? 1 : 0,
					transition: {
						type: 'spring',
						mass: 0.1,
						stiffness: 140
					}
				}}
			/>
		</>
	)
}

const Wrap = styled(motion.div)`
	position: fixed;
	top: 0;
	left: 0;
  background: var(--black);
	width: 20px; 
	height: 20px; 
  border-radius: 50%;  		
	pointer-events: none;
	z-index: 9999;
	opacity: 0;
`

Cursor.propTypes = {
	className: PropTypes.string
}

export default Cursor