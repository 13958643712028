import { graphql, useStaticQuery } from 'gatsby'

const useSiteSettings = () => {

	const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        siteSocialImage: socialImage {
          asset {
            _id
          }
        }
        siteMetaDescription: metaDescription
        siteTitle
				siteDescription: _rawSiteDescription
				cartEmptyText
				cartShippingText: _rawCartShippingText(resolveReferences: {maxDepth: 5})
				cartDonationText: _rawCartDonationText(resolveReferences: {maxDepth: 5})
				addToCartSuccessMessage: _rawAddToCartSuccessMessage
				popUpTitle
				popUpText: _rawPopUpText(resolveReferences: {maxDepth: 5})
				displayPopUp
      }
    }
    `)

	return siteSettings?.sanitySiteSettings
}

export default useSiteSettings
