import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import useSafari from '~utils/useSafari'

const BlurIn = ({ className, children }) => {
	const controls = useAnimation()
	const [ref, inView] = useInView({threshold: 0,})
	const safari = useSafari()

	useEffect(() => {
		if (inView) {
			controls.start('visible')
		}
	}, [controls, inView])

	if(safari){
		return <div className={className}>{children}</div>
	}
	
	return (
		<motion.div 
			className={className}
			ref={ref}
			animate={controls}
			initial="hidden"
			transition={{ duration: 0.7, ease: [0.25, 1, 0.5, 1], delay: 0.2 }}
			variants={{
				visible: { filter: 'blur(0px)' },
				hidden: { filter: 'blur(20px)' }
			}}
		>
			{children}
		</motion.div>
	)
}


BlurIn.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
}

export default BlurIn