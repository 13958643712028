import React from 'react'
import PropTypes from 'prop-types'

const Num = ({ className, children }) => {
	let number = parseInt(children)
	let icon
	if(number === 0){
		icon = '⓪'
	} else if (number > 8) {
		icon = `(${number})`
	} else {
		icon = `&#931${parseInt(children) + 1}`
	}
	return (
		<span style={{}} className={className} dangerouslySetInnerHTML={{__html: icon}}/>
	)
}

Num.propTypes = {
	className: PropTypes.string,
	children: PropTypes.number,
}

export default Num