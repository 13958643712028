import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import sendToMailchimp from '../utils/sendToMailchimp'
import Button from '~components/Button'
import { mobile } from '../styles/global'

const NewsletterSignupForm = ({ className, successMessage, showName, vertical }) => {
	const [values, setValues] = useState({})
	const [errors, setErrors] = useState([])
	const [success, setSuccess] = useState(false)

	const validate = e => {
		e.preventDefault()
		const newErrors = []

		if(!values.email) newErrors.push({
			field: 'email',
			message: 'The email field is required'
		})

		if(values.email && (!values.email.includes('@') || !values.email.includes('.'))) newErrors.push({
			field: 'email',
			message: 'Please check your email address'
		})

		if(newErrors.length === 0){
			submit()
		}
		else{
			setErrors(newErrors)
		}
	}

	const submit = () => {
		sendToMailchimp(values.email, values.fname, values.lname, (err, data) => {
			if(err){
				console.log(err, data)
				setErrors([{
					field: null,
					message: 'Sorry we can\'t sign you up right now.'
				}])
			}
			else{
				setSuccess(true)
			}
		})
	}

	const errorFields = errors.map(error => error.field)

	return (
		<Wrap className={className}>
			<Success css={css`
        opacity: ${success ? '1' : '0'};
        pointer-events: ${success ? 'all' : 'none'};
      `}>
				{successMessage}
			</Success>
			<FormWrap css={css`
        opacity: ${success ? '0' : '1'};
        pointer-events: ${success ? 'none' : 'all'};
        `}>
				<Form onSubmit={e => validate(e)} noValidate vertical={vertical}>
					{showName &&
					<>
						<Input
							type="text"
							name="fname"
							placeholder="First Name"
							onChange={e => setValues({...values, fname: e.target.value})}
							className='h3'
							vertical={vertical}
						/>
						<Input
							type="text"
							name="lname"
							placeholder="Last Name"
							onChange={e => setValues({...values, lname: e.target.value})}
							className='h3'
							vertical={vertical}
						/>
					</>
					}
					<EmailInput vertical={vertical}>
						<Input
							type="email"
							name="email"
							placeholder="Email*"
							vertical={vertical}
							css={css`
								border-color: ${errorFields.includes('email') ? 'var(--gold)' : 'black'};
							`}
							onChange={e => setValues({...values, email: e.target.value})}
							className='h3'
						/>
						{errors?.length > 0 &&
							<Errors vertical={vertical}>
								{errors.map(error => <p key={error.message}>{error.message}</p>)}
							</Errors>
						}
					</EmailInput>
					<Submit type="submit" className={vertical ? 'h3' : 'h2'} vertical={vertical}> 
            Submit
					</Submit>
				</Form>
			</FormWrap>
		</Wrap>
	)
}

const Wrap = styled.div`
  position: relative;
`

const FormWrap = styled.div`
  position: relative;
  transition: opacity 0.3s;
`
const Form = styled.form`
	display: grid;
	width: 100%;
	grid-template-columns: ${props => props.vertical ? '1fr' : '1fr 1fr 1fr max-content'};
	grid-column-gap: var(--l);
	${mobile}{
		grid-template-columns: repeat(6, 1fr);
		grid-column-gap: 20px;
	}
`
const Input = styled.input`
	background-color: transparent;
	border: none;
	border-bottom: 1px solid var(--black);
	text-align: ${props => props.vertical ? 'left' : 'center'};
	padding: var(--xs) 0;
	${props => props.vertical && 'margin-bottom: 20px;'};
	::placeholder{
		color: var(--black);
	}
	${mobile}{
		grid-column: 2/6;
		padding: var(--s) 0;
		margin-bottom: var(--l);
	}
`
const EmailInput = styled.div`
position: relative;
 > input { 
	width: 100%;
	margin-bottom: ${props => props.vertical ? '44px' : '0'};
 }
 ${mobile}{
		grid-column: 2/6;
		margin-bottom: var(--xxxl);
	}
`
const Submit = styled(Button)`
	margin: ${props => props.vertical ? ' 0' : '0 var(--s)'};
	${props => props.vertical && 'text-align: left;'};
	${mobile}{
		grid-column: 2/6;
	}
`
const Errors = styled.div`
	position: absolute;
	top: ${props => props.vertical ? 'auto' : 'calc(100% + var(--xs))'};
	bottom: ${props => props.vertical ? '14px' : 'auto'};
	color: var(--gold);
`
const Success = styled.h5`
  position: absolute;
  top: 0;
  left: 0;
	right: 0;
  transition: opacity 0.3s 0.3s;
	text-align: center;
`

NewsletterSignupForm.propTypes = {
	successMessage: PropTypes.string,
	showName: PropTypes.bool,
	vertical: PropTypes.bool,
}

export default NewsletterSignupForm
