import React from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'

const FadeTransition = ({children, integer, className, duration}) => {

	return (
		<AnimatePresence exitBeforeEnter>
			<motion.div 
				key={integer}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: duration ?? 0.6}}
				className={className}
			>
				{children}
			</motion.div>
		</AnimatePresence>
	)
}

FadeTransition.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.string,
	]),
	integer: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.string,
	]),
	duration: PropTypes.number,
}

export default FadeTransition