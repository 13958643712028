import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { mobile } from '~styles/global'
import Section from '~components/Section'
import Image from '~components/Image'
import RichText from './RichText'
import Button from './Button'
import { Larr, Rarr } from './Svg'
import { css } from '@emotion/react'
import FadeTransition from '~components/FadeTransition'
import { AnimatePresence, motion } from 'framer-motion'

const CarouselModule = ({ className, slides }) => {

	const [slide, setSlide] = useState(undefined)
	const [integer, setInteger] = useState(0)
	const slideLength = slides?.length
	const [panStart, setPanStart] = useState(0)
	const [panEnd, setPanEnd] = useState(0)

	useEffect(() => {
		setSlide(slides[integer])
	}, [slides, integer])

	const nextSlide = ()=> {
		if (integer >= slideLength -1) {
			setInteger(0)
		} else {
			setInteger(integer + 1)
		}
	}
	const prevSlide = ()=> {
		if (integer === 0) {
			setInteger(slideLength -1)
		} else {
			setInteger(integer - 1)
		}
	}

	function onPanStart(event, info) {
		setPanStart(info.point.x)
	}
	function onPanEnd(event, info) {
		setPanEnd(info.point.x)
	}

	useEffect(() => {
		if(panStart > panEnd) {
			prevSlide()
		} else if (panStart < panEnd) {
			nextSlide()
		}
	}, [panEnd])

	return (
		<Wrap className={className}>
			{slides && 
				<>
					<Content>
						<Top>
							<FadeTransition integer={integer}>
								<Subtitle>{slide?.subtitle}</Subtitle>
								<Heading>{slide?.title}</Heading>
							</FadeTransition>
						</Top>
						<Details>	
							<FadeTransition integer={integer}>
								<Text>
									{slide?.text && <RichText content={slide?.text}/>}
									{slide?.button?.text &&
									<LinkButton link={slide?.button?.link} className='h3'>
										{slide?.button?.text}
									</LinkButton>
									}
								</Text>
							</FadeTransition>
							<Arrows>
								<ArrowButton 
									onClick={()=> prevSlide()}
									css={css`
										&:hover{
											svg {
												transform: translateX(-2px);
											}
										}
								`}
								><Larr/></ArrowButton>
								<FadeTransition integer={integer}>
									<h2 className="numbers">{integer + 1}/{slideLength}</h2>
								</FadeTransition>
								<ArrowButton 
									onClick={()=> nextSlide()}
									css={css`
										&:hover{
											svg {
												transform: translateX(2px);
											}
										}
									`}
								><Rarr/></ArrowButton>
							</Arrows>
						</Details>
					</Content>
					<MainImageContainer>
						<AnimatePresence exitBeforeEnter>
							<motion.div 
								onPanStart={onPanStart}
								onPanEnd={onPanEnd}
								key={integer}
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.6}}
							>
								<MainImage 
									image={slide?.image} 
									aspectRatio={0.666}
								/>
							</motion.div>
						</AnimatePresence>
						{/* <FadeTransition integer={integer}>
							<MainImage 
								image={slide?.image} 
								aspectRatio={0.666}
							/>
						</FadeTransition> */}
					</MainImageContainer>
					<DetailsMobile>
						<Text>
							{slide?.text && <RichText content={slide?.text}/>}
							{slide?.button?.text &&
									<LinkButton link={slide?.button?.link} className='h3'>
										{slide?.button?.text}
									</LinkButton>
							}
						</Text>
						<Arrows>
							<ArrowButton onClick={()=> nextSlide()}><Larr/></ArrowButton>
							<h2 className="numbers">{integer + 1}/{slideLength}</h2>
							<ArrowButton onClick={()=> prevSlide()}><Rarr/></ArrowButton>
						</Arrows>
					</DetailsMobile>
				</>
			}
		</Wrap>
	)
}

const Wrap = styled(Section)`
	border-bottom: 1px solid var(--black);
	padding-top: 100px;
	padding-bottom: 149px;
	max-height: 100vh;
	${mobile}{
		padding-top: 20px;
		padding-bottom: 30px;
		max-height: none;
	}
`
const Content = styled.div`
	grid-column: 2/6;
	display: flex;
	text-align: center;
	flex-direction: column;
	justify-content: space-between;
	${mobile}{
		grid-column: span 6;
	}
`
const Subtitle = styled.h2`
	margin-bottom: 55px;
	${mobile}{
		margin-bottom: 20px;
	}
`
const Top = styled.div`
	margin-top: 40px;
`
const Heading = styled.h1`
	${mobile}{
		padding: 0 10%;
		margin-bottom: 40px;
	}
`
const Details = styled.div`
	${mobile}{
		display: none;
	}
`
const DetailsMobile = styled.div`
	display: none;
	text-align: center;
	grid-column: span 6;
	${mobile}{
		display: block;
	}
`
const Text = styled.div`
	margin-bottom: 80px;
	${mobile}{
		padding: 0 5%;
		margin-bottom: 30px;
	}
`
const LinkButton = styled(Button)`
	margin-top: 40px;
	${mobile}{
		margin-top: 29px;
	}
`
const Arrows = styled.div`
	display: flex;
	justify-content: space-between;	
`
const ArrowButton = styled.button`
	svg {
		transform: translateX();
		transition: transform 0.3s;
		width: 20px;
	}
`
const MainImageContainer = styled.div`
	grid-column: 7/13;
	${mobile}{
		grid-column: 2/6;
		margin-bottom: 40px;
	}
`
const MainImage = styled(Image)`
		margin: 0 auto;
		max-width: 50.9vh;
		min-width: 332px;
		display: block;
		${mobile}{
			max-width: none;
			min-width: auto;
		}
`

CarouselModule.propTypes = {
	className: PropTypes.string,
	slides: PropTypes.array,
	children: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
		PropTypes.array,
	]),
}


export default CarouselModule