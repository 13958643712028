const resolveLink = (doc) => {
	if(!doc) return null
	const type = doc._type
	const slug = doc.slug?.current

	switch (type) {
	case 'homePage':
		return '/'
	case 'product':
		return `/products/${slug}/`
	case 'variant':
		return `/products/${doc.content?.shopify?.productSlug}/?v=${doc.content?.shopify?.variantId}`
	case 'page':
		return `/${slug}/`
	case 'journalPage':
		return '/journal/'
	case 'productIndex':
		return '/store/'
	case 'productCategory':
		return `/store/${slug}/`
	case 'topic':
		return `/journal/${slug}/`
	case 'article':
		return `/articles/${slug}/`
	case 'aboutPage':
		return `/${slug}/`
	default:
		return `/${slug}/`
	}

}

export default resolveLink
