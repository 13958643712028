import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import SanityLink from './SanityLink'
import { mobile, tablet } from '../styles/global'
import { Rarr } from './Svg'
import styled from '@emotion/styled'

const Button = ({ className, children, link, onClick, to, noArrow, largeArrow }) => {
	const [hover, setHover] = useState(false)
	if(link || to) {
		return (
			<div 
				className={className} 
				onMouseEnter={()=> setHover(true)}
				onMouseLeave={()=> setHover(false)}
				css={css`
					display: inline-flex;
				`}
			> 
				<SanityLink 
					css={css`
					display: inline-flex;
				`} 
					link={link}
					to={to}
				>
					{children}
					{!noArrow &&
						<RightArrow 
							css={css`
									transform: ${hover ? 'translateX(2px)' : 'translateX(0px)'};
						`}/>
					}
				</SanityLink>
			</div>
		)
	} else if (onClick){
		return(
			<button 
				className={className} 
				onMouseEnter={()=> setHover(true)}
				onMouseLeave={()=> setHover(false)}
				onClick={onClick}
			>
				{children}
				{!noArrow &&
				<RightArrow css={css`
				width: ${largeArrow ? '20px' : '13px'};
				height: ${largeArrow ? '20px' : '13px'};
				transform: ${hover ? 'translateX(2px)' : 'translateX(0px)'};
			`}/>}
			</button>
		)
	} else {
		return(
			<button 
				className={className} 
				onMouseEnter={()=> setHover(true)}
				onMouseLeave={()=> setHover(false)}
				onClick={onClick}
			>
				{children}
				{!noArrow &&
				<RightArrow css={css`
					width: ${largeArrow ? '20px' : '13px'};
					height: ${largeArrow ? '20px' : '13px'};
					transform: ${hover ? 'translateX(2px)' : 'translateX(0px)'};
				`}/>
				}
			</button>
		)
	}
}

const RightArrow = styled(Rarr)`
	width: 13px; 
	height: 13px; 
	margin-left: 10px;
	transition: transform 0.3s;
	position: relative;
	top: 1px;
	${tablet}{
		top: -1px;
	}
	${mobile}{
		top: 0px;
		margin-left: 5px;
		width: 12px;
		height: 12px;
	}
`
Button.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
		PropTypes.string,
	]),
	link: PropTypes.object,
	onClick: PropTypes.func,
	to: PropTypes.string,
	noArrow: PropTypes.bool,
}

export default Button