import { graphql, useStaticQuery } from 'gatsby'

const useNavData = () => {

	const navData = useStaticQuery(graphql`
    query {
      nav: sanityNavigation {
        storeHeading
				storeItems{
					category {
						title
						slug {
							current
						}
					}
					products {
						title
						content {
							variants {
								...variantThumb
							}
						}
					}
				}
				aboutHeading
				journalHeading
				featuredArticles{
					...articleThumb
				}
				indexTabs {
					title
					slug {
						current
					}
				}
				exploreHeading
				exploreMenu{
					text
					link {
						...link
					}
				}
				newsletterHeading
				newsletterSuccess
				acknowledgement: _rawAcknowledgement
				footerMenu{
					text
					link {
						...link
					}
				}
      }
			aboutPages: allSanityAboutPage(sort: {fields: _createdAt, order: ASC}) {
				aboutPages: nodes {
					title
					slug {
						current
					}
					content {
						headerImage{
							...imageWithAlt
						}
					}
				}
			}
			sanitySiteSettings {
				siteDescription: _rawSiteDescription
			}
    }
  `)

	return {
		...navData?.nav,
		...navData?.aboutPages,
		...navData?.sanitySiteSettings
	}
}

export default useNavData
