import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { mobile, tablet } from '../styles/global'
import {  PlusCircle } from './Svg'

const Accordion = ({ className, children, title, constrained }) => {

	const [open, setOpen] = useState(false)

	return(
		<Wrap 
			className={className} 
		>
			<Header 
				onClick={() => setOpen(!open)}
				css={css`padding: ${constrained ? 'var(--s) var(--l)' : 'var(--s)'};`}
			>
				<h3>{title}</h3>
				<Close 
					css={css`
						transform: ${open ? 'rotate(45deg)' : 'rotate(0deg)'};
					`}
				/>
			</Header>
			<SlideDown 
				className={'my-dropdown-slidedown'} 
				css={css`
          transition: 0.4s ease-in-out;
      `}>
				{open ? 
					<Content css={css`padding: ${constrained ? 'var(--xs) var(--l) var(--m)' : 'var(--xs) var(--s) var(--m)'};`}
					>{children}
					</Content>
					: null
				}
			</SlideDown>
		</Wrap>
	)
}

const Wrap = styled.div`
`
const Header = styled.button`
	width: 100%;
	text-align: left;
	display: flex;
	justify-content: space-between;
	align-items: center;
`
const Close = styled(PlusCircle)`
	width: 13px;
	height: 13px;
	margin-right: 3px;
	transition: transform 0.3s;
	${mobile}{
		margin-right: 0px;
	}
`
const Content = styled.div`
	max-width: 80%;
	${tablet}{
		max-width: 100%;
	}
`

Accordion.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array,
		PropTypes.object,
	]),
	constrained: PropTypes.bool,
	onChange: PropTypes.func,
	currentlyOpenItem: PropTypes.string,
}

export default Accordion