module.exports = {
	siteUrl: 'https://jonnetti.com',
	previewUrl: 'https://preview-jonnetti.gtsb.io',
	previewWebhook: 'https://webhook.gatsbyjs.com/hooks/data_source/6cc7a41a-176f-4e24-aff6-962b0b47784b',
	netlifySiteName: 'jon-netti',
	netlifyAppId: '0c411034-e7fc-4464-98a2-ff936d99bccd',
	netlifyBuildHookId: '615b992d7f5ec23e9268b3ed',
	mailchimpUrl: 'https://gmail.us6.list-manage.com',
	mailchimpUser: '8595d179700e8e66c10f86c11',
	mailchimpId: 'fad1e22f76',
	gTag: 'G-XXXXXXXXX'
}