import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css, Global } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import useSiteSettings from '~utils/useSiteSettings'
import RichText from '~components/RichText'
import Newsletter from '~components/Newsletter'
import { Close } from '~components/Svg'
import { useState } from 'react'
import Cookies from 'js-cookie'
import { useEffect } from 'react'

const PopUp = ({ className }) => {

	const {popUpTitle, popUpText, displayPopUp} = useSiteSettings()
	const [show, setShow] = useState(false)

	const close = () => {
		Cookies.set('HIDE_POPUP', true, { expires: 7 })
		setShow(false)
	}	

	useEffect(() => {
		if(!Cookies.get('HIDE_POPUP')){
			setTimeout(() => {
				setShow(true)
			}, 9000)
		} 
	}, [])

	

	return (
		displayPopUp ?
			<>
				{	show &&	<Global styles={css`
				body, html {
					overflow: hidden !important;
				}
			`}/>}
				<Wrap className={className}>
					<Container show={show}>
						<CloseButton onClick={() => close()}>
							<Close />
						</CloseButton>
						<Title className='h3'>{popUpTitle}</Title>
						<Text className='h3'><RichText content={popUpText}/></Text>
						<StyledNewsletter vertical showName successMessage='Got it. Thanks!'/>
					</Container>
				</Wrap>
				<Overlay onClick={() => close()} show={show}/>
			</>
			:
			<></>
	)
}

const Wrap = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 201;
	pointer-events: none;
`
const Container = styled.div`
	width: 100%;
	max-width: 388px;
	border: 1px solid var(--black);
	background-color: var(--beige);
	padding: 44px 80px 44px 38px;
	box-sizing: border-box;
	position: relative;
	pointer-events: ${props => props.show ? 'all' : 'none'};
	opacity: ${props => props.show ? '1' : '0'};
	transition: opacity 0.6s;
`
const CloseButton = styled.button`
	position: absolute;
	top: 38px;
	right: 36px;
	width: 14px;
	:after{
		content: '';
		width: 20px;
		height: 20px;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	> svg {
		display: block;
	}
`
const Overlay = styled.button`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(198, 195, 184, 0.92);
	mix-blend-mode: multiply;
	z-index: 200;
	pointer-events: ${props => props.show ? 'all' : 'none'};
	opacity: ${props => props.show ? '1' : '0'};
	transition: opacity 0.6s;
`
const Title = styled.div`
	margin-bottom: 18px;
`
const Text = styled.div`
	margin-bottom: 24px;
`
const StyledNewsletter = styled(Newsletter)`

`

PopUp.propTypes = {
	className: PropTypes.string
}

export default PopUp