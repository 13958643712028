import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import Player from '@vimeo/player'
import RichText from '~components/RichText'
import Image from '~components/Image'
import styled from '@emotion/styled'
import { Play } from './Svg'

const VideoModule = ({ video, className, placeHolder }) => {
	let vimeo
	let youtube

	if (video?.includes('vimeo')){
		vimeo = true
	} else if (video?.includes('youtube') && video?.includes('?v=')) {
		youtube = true
		video = video.split('?v=')[1]
		video = `https://www.youtube.com/embed/${video}`
	} else if (video?.includes('youtube')) {
		youtube = true
		video = video.split('embed/')[1]
		video = `https://www.youtube.com/embed/${video}`
	} 

	// youtube play function 
	const [play, setPlay] = useState(false)
	const [hover, setHover] = useState(false)

	const url = play ? `${video}?autoplay=1` : video

	// Vimeo variables
	let player

	const videoContainer = node => {
		let options = {
			id: video,
			loop: true,
			byline: false,
			title: false,
			portrait: false,
		}
		if(node) player = new Player(node, options)
	}

	const playVideo = () => {
		setPlay(true)
		player?.play()
	}
	
	useEffect(() => {
		// player?.play()
	}, [])

	return (
		<Wrap className={className}>
			<Container 
				onMouseEnter={()=> setHover(true)}
				onMouseLeave={()=> setHover(false)} 
				onClick={()=> playVideo()}
			>
				<Overlay css={css`
					pointer-events: ${ play ? 'none' : 'auto' };
					opacity: ${ play ? '0' : '1' };
				`}>
					<PlayButton css={css`
						transform:${hover ? 'scale(1.2)' : 'scale(1)'}; 
					`}>
						<Play/>
					</PlayButton>
					<ImageContainer>
						<Image image={placeHolder} aspectRatio={1.7778}/>
					</ImageContainer>
				</Overlay>
				<VideoContainer>
					{youtube && 
					<iframe 
						src={url} 
						frameBorder='0' 
						allowFullScreen
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						css={css`
							${videoCss}
					`}>
					</iframe>}
					{vimeo && 
					<Vimeo ref={videoContainer} css={css`
						iframe{
							${videoCss}
						}
					`}/>
					}
				</VideoContainer>
			</Container>
			{placeHolder?.caption && 
			<Caption>
				<h3><RichText content={placeHolder?.caption}/></h3>
			</Caption>
			}
		</Wrap>
	)
}
const Wrap = styled.div`
`
const Container = styled.div`
	position: relative;
	cursor: pointer;
`
const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	transition: 0.5s opacity;
	display: grid;
	align-content: end;
	justify-content: start;
	padding: 24px;
`
const ImageContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
`
const PlayButton = styled.div`
	z-index: 2;
	width: 22px;
	height: 22px;
	transition: transform 0.3s;
`
const VideoContainer = styled.div`
	position: relative; 
	padding-bottom: 56.25%;
	height: 0; 
	overflow: hidden; 
	max-width: 100%;
`
const videoCss = `
	position: absolute; 
	top: 0; 
	left: 0; 
	width: 100%; 
	height: 100%; 
`
const Vimeo = styled.div`
	position: relative; 
	padding-bottom: 56.25%; 
	height: 0; 
	overflow: hidden; 
	max-width: 100%;
`
const Caption = styled.div`
	margin-top: 20px;
`


VideoModule.propTypes = {
	className: PropTypes.node,
	placeHolder: PropTypes.object,
	video: PropTypes.string,
}

export default VideoModule
