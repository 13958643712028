import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { css, Global } from '@emotion/react'
import GlobalStyles from '~styles/global'
import Header from '~components/Header'
import Cart from '~components/Cart'
import Footer from '~components/Footer'
import smoothscroll from 'smoothscroll-polyfill'
import { mobile } from '~styles/global'
import Transition from '~components/Transition'
import { useSiteState, useCart } from '../context/siteContext'
import styled from '@emotion/styled'
import { useLocation } from '@reach/router'
import useVH from 'react-vh'
import '~styles/static.css'
import Cursor from '~components/Cursor'
import useBreakpoint from '~utils/useBreakpoint'
import useSafari from '~utils/useSafari'
import PopUp from '~components/PopUp'

const Layout = ({ children }) => {
	useVH()
	const { isMobile } = useBreakpoint()
	const [siteState, setSiteState] = useSiteState()
	const { closeCart } = useCart()
	const location = useLocation()
	const [offSetTop, setOffSetTop] = useState()
	const [transparentHeader, setTransparentHeader] = useState(false)
	const safari = useSafari()
	const [fixedBody, setFixedBody] = useState()

	useEffect(() => {
		smoothscroll.polyfill()
	}, [])

	// Close the menus when the location changes, allowing time for transition
	useEffect(() => {
		window.setTimeout(() => {
			setSiteState(prevState => ({
				...prevState,
				cartOpen: false,
				menuOpen: false,
				mobileNavOpen: false,
			}))
		}, 800)
		// eslint-disable-next-line
  }, [location])

	const overlayClick = () => {
		if (siteState.cartOpen) {
			setSiteState(prevState => ({
				...prevState,
				cartOpen: false,
			}))
		} else if (siteState.menuOpen) {
			setSiteState(prevState => ({
				...prevState,
				menuOpen: false,
			}))
		}
	}

	useLayoutEffect(() => {
		setOffSetTop(window.pageYOffset)
		window.addEventListener('scroll', ()=> {
			setOffSetTop(window.pageYOffset)
		})
		window.removeEventListener('scroll', ()=> {
			setOffSetTop(window.pageYOffset)
		})
	}, [])

	// Handle expanded and tarnsparent header states
	useEffect(() => {
		if(location?.pathname === '/'){
			if(offSetTop === 0){
				setSiteState(prevState => ({
					...prevState,
					headerExpanded: true,
				}))
			} 
			if(offSetTop > 0){
				setSiteState(prevState => ({
					...prevState,
					headerExpanded: false,
				}))
			} 
			if (isMobile && (offSetTop <= (window.innerHeight - 200))){
				setTransparentHeader(true)
			} 
			if (!isMobile && (offSetTop <= (window.innerHeight - 120))){
				setTransparentHeader(true)
			} 
			if (isMobile && (offSetTop > (window.innerHeight - 200))){
				setTransparentHeader(false)
			}
			if (!isMobile && (offSetTop > (window.innerHeight - 120))){
				setTransparentHeader(false)
			}
		} else {
			setSiteState(prevState => ({
				...prevState,
				headerExpanded: false,
			}))
			setTransparentHeader(false)
		}
	}, [offSetTop, location])

	useEffect(() => {
		if (location.pathname.includes('products')){
			setFixedBody(false)
		} else if (siteState.cartOpen || siteState.menuOpen){
			setFixedBody(true)
		} else {
			setFixedBody(false)
		}
	}, [siteState.cartOpen, siteState.menuOpen, location])

	useEffect(() => {
		if(fixedBody){
			console.log(document.documentElement)
			console.log(document.body)
			// disablePageScroll
			// document.body.style.height = window.innerHeight + 'px'
			// document.body.style.overflow = 'hidden'
			document.documentElement.style.height = window.innerHeight + 'px'
			document.documentElement.style.overflow = 'hidden'
		} else {
			// enablePageScroll
			document.body.style.height = 'visible'
			document.body.style.overflow = 'visible'
			document.documentElement.style.height = 'visible'
			document.documentElement.style.overflow = 'visible'
		}
	}, [fixedBody])

	return (
		<>
			<GlobalStyles />
			{/* <Global 
				styles={css`
					html, body{
						overflow: ${fixedBody ? 'hidden' : 'visible'};
					}
			`}/> */}
			<Header transparent={transparentHeader} currentSlug={location.pathname.replace(/\//g,'')} fixedBody={fixedBody}/>
			<Cart open={siteState.cartOpen} />
			<PopUp />
			<main>
				<Transition>
					<div css={css`
            min-height: calc(100vh);
            display: flex;
            flex-direction: column;
						padding-top: ${location?.pathname === '/' ? '0px' : '54px'};
            ${mobile}{
              padding-top: ${location?.pathname === '/' ? '0px' : '46px'};
              min-height: calc(100vh);
            }
          `}>
						{children}
						<Footer />
					</div>
				</Transition>
			</main>
			<Overlay 
				onClick={() => overlayClick()}
				css={css`
          pointer-events: ${siteState.cartOpen || siteState.menuOpen ? 'all' : 'none'};
      `}/>
			{!safari &&
				<Cursor />
			}
		</>
	)
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

Layout.propTypes = {
	children: PropTypes.node,
}

export default Layout
