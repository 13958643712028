/* eslint-disable react/prop-types */
import React from 'react'

export const Face = ({ className }) => (
	<svg className={className} viewBox="0 0 99 106" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="20" cy="20" r="20" fill="currentColor"/>
		<path d="M49.5 105.5C76.8381 105.5 99 83.3381 99 56H0C0 83.3381 22.1619 105.5 49.5 105.5Z" fill="currentColor"/>
		<circle cx="79" cy="20" r="20" fill="currentColor"/>
	</svg>
)

export const Play = ({ className }) => (
	<svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path fillRule="evenodd" clipRule="evenodd" d="M11 1.75C5.89137 1.75 1.75 5.89137 1.75 11C1.75 16.1086 5.89137 20.25 11 20.25C16.1086 20.25 20.25 16.1086 20.25 11C20.25 5.89137 16.1086 1.75 11 1.75ZM0.25 11C0.25 5.06294 5.06294 0.25 11 0.25C16.9371 0.25 21.75 5.06294 21.75 11C21.75 16.9371 16.9371 21.75 11 21.75C5.06294 21.75 0.25 16.9371 0.25 11Z" fill="currentColor"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M8.64611 6.33874C8.88997 6.20823 9.18588 6.22253 9.41603 6.37596L15.416 10.376C15.6247 10.5151 15.75 10.7492 15.75 11C15.75 11.2508 15.6247 11.4849 15.416 11.624L9.41603 15.624C9.18588 15.7775 8.88997 15.7918 8.64611 15.6613C8.40224 15.5307 8.25 15.2766 8.25 15V7C8.25 6.7234 8.40224 6.46926 8.64611 6.33874ZM9.75 8.40139V13.5986L13.6479 11L9.75 8.40139Z" fill="currentColor"/>
	</svg>
)

export const Cross = ({className}) => (
	<svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path d="M5.98891 10.5848C3.44567 10.5797 1.37982 8.5138 1.37469 5.97057C1.36956 3.42734 3.42709 1.3698 5.97032 1.37493C8.51355 1.38006 10.5794 3.44592 10.5845 5.98915C10.5897 8.53238 8.53214 10.5899 5.98891 10.5848Z" stroke="currentColor" strokeWidth="0.77" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M7.22962 7.22985L4.72963 4.72986" stroke="currentColor" strokeWidth="0.77" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M7.22461 4.73486L4.73463 7.22484" stroke="currentColor" strokeWidth="0.77" strokeLinecap="round" strokeLinejoin="round"/>
	</svg>
)

export const Plus = ({className}) => (
	<svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path d="M8.29436 8.29086C6.49196 10.086 3.56906 10.086 1.76667 8.29086C-0.0348661 6.49659 -0.0348661 3.58813 1.76667 1.79385C3.56906 -0.00128385 6.49196 -0.00128385 8.29436 1.79385C10.0959 3.58813 10.0959 6.49659 8.29436 8.29086Z" stroke="currentColor" strokeWidth="0.77" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M6.79828 5.04242H3.26276" stroke="currentColor" strokeWidth="0.77" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M5.03052 3.28174V6.8031" stroke="currentColor" strokeWidth="0.77" strokeLinecap="round" strokeLinejoin="round"/>
	</svg>
)


export const Rarr = ({className}) => (
	<svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<g clipPath="url(#clip0)">
			<path fillRule="evenodd" clipRule="evenodd" d="M7.30762 5.00015C7.30762 5.21257 7.13542 5.38477 6.923 5.38477L3.07685 5.38477C2.86443 5.38477 2.69223 5.21257 2.69223 5.00015C2.69223 4.78773 2.86443 4.61553 3.07685 4.61553L6.923 4.61553C7.13542 4.61553 7.30762 4.78773 7.30762 5.00015Z" fill="currentColor"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M4.72796 7.19545C4.57776 7.04525 4.57776 6.80173 4.72796 6.65153L6.37907 5.00041L4.72796 3.3493C4.57776 3.1991 4.57776 2.95557 4.72796 2.80537C4.87816 2.65517 5.12169 2.65517 5.27189 2.80537L7.19497 4.72845C7.34517 4.87865 7.34517 5.12218 7.19497 5.27238L5.27189 7.19545C5.12169 7.34566 4.87816 7.34566 4.72796 7.19545Z" fill="currentColor"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M5 9.23077C7.33659 9.23077 9.23077 7.33659 9.23077 5C9.23077 2.66341 7.33659 0.769231 5 0.769231C2.66341 0.769231 0.769231 2.66341 0.769231 5C0.769231 7.33659 2.66341 9.23077 5 9.23077ZM5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10Z" fill="currentColor"/>
		</g>
	</svg>
)

export const Larr = ({className}) => (
	<svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<g clipPath="url(#clip0)">
			<path fillRule="evenodd" clipRule="evenodd" d="M2.69238 4.99985C2.69238 4.78743 2.86458 4.61523 3.077 4.61523L6.92315 4.61523C7.13557 4.61523 7.30777 4.78743 7.30777 4.99985C7.30777 5.21227 7.13557 5.38447 6.92315 5.38447L3.077 5.38447C2.86458 5.38447 2.69238 5.21227 2.69238 4.99985Z" fill="currentColor"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M5.27204 2.80455C5.42224 2.95475 5.42224 3.19827 5.27204 3.34847L3.62093 4.99959L5.27204 6.6507C5.42224 6.8009 5.42224 7.04443 5.27204 7.19463C5.12184 7.34483 4.87831 7.34483 4.72811 7.19463L2.80503 5.27155C2.65483 5.12135 2.65483 4.87782 2.80503 4.72762L4.72811 2.80455C4.87831 2.65434 5.12184 2.65434 5.27204 2.80455Z" fill="currentColor"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M5 0.76923C2.66341 0.769231 0.769229 2.66341 0.769229 5C0.76923 7.33659 2.66341 9.23077 5 9.23077C7.33659 9.23077 9.23077 7.33659 9.23077 5C9.23077 2.66341 7.33659 0.76923 5 0.76923ZM5 -5.1656e-07C2.23857 -2.75149e-07 -1.6322e-06 2.23858 -1.39079e-06 5C-1.14938e-06 7.76142 2.23858 10 5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 -7.57972e-07 5 -5.1656e-07Z" fill="currentColor"/>
		</g>
	</svg>
)

export const LargeRarr = ({className}) => (
	<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<g clipPath="url(#clip0)">
			<path fillRule="evenodd" clipRule="evenodd" d="M14.3463 10C14.3463 10.2761 14.1225 10.5 13.8463 10.5L6.15401 10.5C5.87786 10.5 5.65401 10.2761 5.65401 10C5.65401 9.72386 5.87786 9.5 6.15401 9.5L13.8463 9.5C14.1225 9.5 14.3463 9.72386 14.3463 10Z" fill="currentColor"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M9.64624 14.1997C9.45098 14.0045 9.45098 13.6879 9.64624 13.4926L13.1388 10L9.64624 6.50744C9.45098 6.31217 9.45098 5.99559 9.64624 5.80033C9.8415 5.60507 10.1581 5.60507 10.3533 5.80033L14.1995 9.64648C14.3948 9.84175 14.3948 10.1583 14.1995 10.3536L10.3533 14.1997C10.1581 14.395 9.8415 14.395 9.64624 14.1997Z" fill="currentColor"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="currentColor"/>
		</g>
	</svg>
)

export const LargeLarr = ({className}) => (
	<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<g clipPath="url(#clip0)">
			<path fillRule="evenodd" clipRule="evenodd" d="M5.65369 10C5.65369 10.2761 5.87754 10.5 6.15369 10.5L13.846 10.5C14.1221 10.5 14.346 10.2761 14.346 10C14.346 9.72386 14.1221 9.5 13.846 9.5L6.15369 9.5C5.87754 9.5 5.65369 9.72386 5.65369 10Z" fill="currentColor"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M10.3538 14.1997C10.549 14.0045 10.549 13.6879 10.3538 13.4926L6.86116 10L10.3538 6.50744C10.549 6.31217 10.549 5.99559 10.3538 5.80033C10.1585 5.60507 9.84191 5.60507 9.64665 5.80033L5.8005 9.64648C5.60524 9.84175 5.60524 10.1583 5.8005 10.3536L9.64665 14.1997C9.84192 14.395 10.1585 14.395 10.3538 14.1997Z" fill="currentColor"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19ZM10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20Z" fill="currentColor"/>
		</g>
	</svg>
)

export const Logo = ({ className }) =>
	<svg className={className} viewBox="0 0 247 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M14.4359 19.1124C14.4359 21.3367 13.5094 22.8654 11.3991 22.8654C9.38523 22.8654 8.37685 21.3309 8.37685 18.8318H0C0 25.0809 3.58924 29.8802 11.3991 29.8802C18.4139 29.8802 22.8537 26.3318 22.8537 18.3087V0.876831H14.4359V19.1124Z" fill="currentColor"/>
		<path d="M43.3455 0C33.5481 0 27.7812 6.97681 27.7812 15C27.7812 23.0232 33.5481 30 43.3455 30C53.1428 30 58.9124 23.0261 58.9124 15C58.9124 6.97389 53.1457 0 43.3455 0ZM43.3455 22.5058C38.8297 22.5058 36.5351 19.0335 36.5351 15C36.5351 10.9665 38.8326 7.5 43.3484 7.5C47.8642 7.5 50.2053 10.9694 50.1643 15C50.2023 19.0335 47.9022 22.5058 43.3455 22.5058Z" fill="currentColor"/>
		<path d="M80.6851 16.9378L72.2966 0.88855H63.8291V29.1144H71.8932V13.0651L80.2818 29.1144H88.7492V0.88855H80.6851V16.9378Z" fill="currentColor"/>
		<path d="M153.726 16.9378L145.338 0.88855H136.87V29.1144H144.934V13.0651L153.323 29.1144H161.79V0.88855H153.726V16.9378Z" fill="currentColor"/>
		<path d="M166.897 29.1144H185.042V22.8244H175.286V18.107H185.042V12.0976H175.286V7.17849H185.042V0.88855H166.897V29.1144Z" fill="currentColor"/>
		<path d="M210.558 0.88855H189.225V7.17849H196.482V29.1144H204.911V7.17849H210.558H212.169H217.816V29.1144H226.242V7.17849H233.503V0.88855H212.169H210.558Z" fill="currentColor"/>
		<path d="M246.07 0.88562H237.682V29.1144H246.07V0.88562Z" fill="currentColor"/>
	</svg>

export const DarrSmall = ({ className }) =>
	<svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<g clipPath="url(#clip0)">
			<path d="M6.5 4L6.5 9" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M9 6.5L6.5 9L4 6.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
			<circle cx="6.5" cy="6.5" r="6" transform="rotate(90 6.5 6.5)" stroke="currentColor"/>
		</g>
	</svg>

export const PlusCircle = ({ className }) =>
	<svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path fillRule="evenodd" clipRule="evenodd" d="M2.01004 2.00194C0.358724 3.6466 0.358724 6.31313 2.01004 7.95779C3.66135 9.60245 6.33865 9.60245 7.98996 7.95779C9.64128 6.31313 9.64128 3.6466 7.98996 2.00194C6.33865 0.35728 3.66135 0.35728 2.01004 2.00194ZM8.53553 8.50116C6.58291 10.4459 3.41709 10.4459 1.46447 8.50116C-0.488155 6.5564 -0.488155 3.40333 1.46447 1.45857C3.41709 -0.486189 6.58291 -0.486189 8.53553 1.45857C10.4882 3.40333 10.4882 6.5564 8.53553 8.50116ZM7.15354 4.98019C7.15354 5.19239 6.98082 5.36441 6.76776 5.36441H5.38577V6.74087C5.38577 6.95307 5.21306 7.12509 5 7.12509C4.78694 7.12509 4.61423 6.95307 4.61423 6.74087V5.36441H3.23224C3.01918 5.36441 2.84646 5.19239 2.84646 4.98019C2.84646 4.76798 3.01918 4.59596 3.23224 4.59596H4.61423V3.21951C4.61423 3.0073 4.78694 2.83528 5 2.83528C5.21306 2.83528 5.38577 3.0073 5.38577 3.21951V4.59596H6.76776C6.98082 4.59596 7.15354 4.76798 7.15354 4.98019Z" fill="black"/>
	</svg>
	
	
	
	
	

export const JNLetters = ({ className}) => 
	<svg viewBox="0 0 78 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path d="M0 12.8H6C6 14.6 6.7 15.7 8.2 15.7C9.7 15.7 10.4 14.6 10.4 13V0H16.4V12.4C16.4 18.1 13.2 20.7 8.2 20.7C2.6 20.7 0 17.3 0 12.8Z" fill="currentColor"/>
		<path d="M50.4 0H56.4L62.4 11.5V0H68.2V20.1H62.2L56.2 8.6V20.1H50.4V0Z" fill="currentColor"/>
		<path d="M21.6 20.1C23.0359 20.1 24.2 18.9359 24.2 17.5C24.2 16.0641 23.0359 14.9 21.6 14.9C20.1641 14.9 19 16.0641 19 17.5C19 18.9359 20.1641 20.1 21.6 20.1Z" fill="currentColor"/>
		<path d="M74.7 20.1C76.1359 20.1 77.3 18.9359 77.3 17.5C77.3 16.0641 76.1359 14.9 74.7 14.9C73.2641 14.9 72.1 16.0641 72.1 17.5C72.1 18.9359 73.2641 20.1 74.7 20.1Z" fill="currentColor"/>
	</svg>

export const Tick = ({ className, ticked }) => 
	<svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<circle cx="6.5" cy="6.5" r="6" stroke="currentColor"/>
		{ticked &&
			<path d="M9 4.5L6 8.5L4 6.30769" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
		}
	</svg>


export const Line = ({ className }) => 
	<svg viewBox="0 0 19 2" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path fillRule="evenodd" clipRule="evenodd" d="M19 2H0V0.5H19V2Z" fill="currentColor"/>
	</svg>


export const Close = ({ className }) => 
	<svg className={className} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M1.08333 6.5C1.08333 9.49154 3.50846 11.9167 6.5 11.9167C9.49154 11.9167 11.9167 9.49154 11.9167 6.5C11.9167 3.50846 9.49154 1.08333 6.5 1.08333C3.50846 1.08333 1.08333 3.50846 1.08333 6.5ZM6.5 13C2.91015 13 -4.41041e-07 10.0898 -2.84124e-07 6.5C-1.27207e-07 2.91015 2.91015 -4.41041e-07 6.5 -2.84124e-07C10.0899 -1.27207e-07 13 2.91015 13 6.5C13 10.0899 10.0899 13 6.5 13Z" fill="currentColor"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M8.5081 8.50801C8.29656 8.71955 7.9536 8.71955 7.74206 8.50801L4.49206 5.25801C4.28053 5.04648 4.28053 4.70351 4.49206 4.49198C4.7036 4.28044 5.04656 4.28044 5.2581 4.49198L8.5081 7.74198C8.71963 7.95351 8.71963 8.29648 8.5081 8.50801Z" fill="currentColor"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M8.5081 4.49198C8.71963 4.70351 8.71963 5.04648 8.5081 5.25801L5.2581 8.50801C5.04656 8.71955 4.7036 8.71955 4.49206 8.50801C4.28053 8.29648 4.28053 7.95351 4.49206 7.74198L7.74207 4.49198C7.9536 4.28044 8.29656 4.28044 8.5081 4.49198Z" fill="currentColor"/>
	</svg>

