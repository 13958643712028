import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Image from '~components/Image'
import RichText from './RichText'

const Figure = ({ className, aspectRatio, content }) => {
	return (
		<Wrap className={className}>
			<div>
				<FigureImage 
					image={content} 
					aspectRatio={aspectRatio}
				/>
				{content?.caption && 
					<Figurecaption>
						<h3><RichText content={content?.caption}/></h3>
					</Figurecaption>
				}
			</div>
		</Wrap>
	)
}

const Wrap = styled.div`
`
const Figurecaption = styled.figcaption`
	margin-top: 20px;
	> h3{
		line-height: 1.4;
	}
`
const FigureImage = styled(Image)`
`

Figure.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
	aspectRatio: PropTypes.number,
}

export default Figure