import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { useLocation } from '@reach/router'
import { Logo } from '~components/Svg'
import useSiteSettings from '~utils/useSiteSettings'
import BlockContent from '@sanity/block-content-to-react'
import { mobile } from '~styles/global'
import { useSiteState, useCart } from '../context/siteContext'
import RichText from '~components/RichText'
import useFontsReady from '~utils/useFontsReady'
import checkClipPathSupport from '~utils/checkClipPathSupport'

const Transition = ({ children }) => {

	const isDev = process.env.NODE_ENV === 'development' && !process.env.GATSBY_PREVIEW

	let location = useLocation()
	if(location.pathname.includes('/journal')){
		location.pathname = 'journal'
	}
	if(location.pathname.includes('/store')){
		location.pathname = 'store'
	}
	if(location.pathname.includes('/range') || location.pathname.includes('/brand')){
		location.pathname = 'about'
	}
	const screenControls = useAnimation()
	const wrapperControls = useAnimation()
	const textControls = useAnimation()
	const [init, setInit] = useState()
	const [transitioning, setTransitioning] = useState(true)
	const { siteDescription } = useSiteSettings()
	const fontsReady = useFontsReady()

	useEffect(() => {
		sequence()
		setInit(true)
	}, [location.pathname])

	function pause(ms) {
		return new Promise(resolve => setTimeout(resolve, ms))
	}
	
	const sequence = async () => {
		setTransitioning(true)
		if(!init){
			await pause(1000)
		}
		screenControls.start('start')
		await (screenControls.start('middle') && textControls.start('middle'))
		if(location.pathname === '/'){
			// give pixi some time to load
			await pause(2000)
		}
		textControls.start('end')
		await pause(300)
		await (screenControls.start('end') && wrapperControls.start('entered'))
		screenControls.set('start')
		textControls.set('start')
		return setTransitioning(false)
	}

	const textVariants = {
		start: { 
			y: -250,
		},
		middle: { 
			y: 0,
		},
		end: {
			y: 550,
			transition: { duration: 1, easing: [0.55, 0, 1, 0.45] },
		}
	}

	const wrapperVariants = {
		enter: { 
			y: -350
		},
		entered: { 
			y: 0
		},
		exit: { 
			y: 350
		},
	}

	// if(isDev) return <div>{children}</div>

	return (
		<div /* css={css`transform: translateZ(0);` }*/>
			{transitioning && 
				<Global styles={css`
					body{
						pointer-events: none;
					}
				`}/>
			}
			<LoadingScreen 
				animate={screenControls}
				initial='middle'
				transition={{ type: 'spring', stiffness: 30 }}
				variants={{
					start: { 
						// clipPath: 'inset(-1% 0 100% 0)',
						transform: 'translateY(-100%)'
					},
					middle: { 
						// clipPath: 'inset(-1% 0 -1% 0)',
						delay: 3,
						transform: 'translateY(-0.05%)'
					},
					end: {
						// clipPath: 'inset(100% 0 -1% 0)'
						transform: 'translateY(100%)'
					}
				}}
			>	
				<motion.div
					animate={textControls}
					initial='middle'
					transition={{ type: 'spring', stiffness: 30 }}
					variants={textVariants}
				>
					{fontsReady && <Logo />}
				</motion.div>
				<Text 
					animate={textControls}
					transition={{ type: 'spring', stiffness: 30 }}
					variants={textVariants}
					className='h2'
				>	
					<div css={css`
						opacity: ${fontsReady ? 1 : 0};
						transition: opacity 0.3s;
					`}>
						<RichText content={siteDescription} />
					</div>
				</Text>
			</LoadingScreen>
			<AnimatePresence exitBeforeEnter>
				<div key={location.pathname}>
					<motion.div 
						initial='enter'
						animate={wrapperControls}
						exit={{ y: 350, opacity: 1 }}
						transition={{ type: 'spring', stiffness: 30 }}
						variants={wrapperVariants}
					>
						{children}
					</motion.div>
				</div>
			</AnimatePresence>
		</div>
	)
}

const LoadingScreen = styled(motion.div)`
	background-color: var(--gold); 
	position: fixed;
	top: 0px;
	height: calc(var(--vh, 1vh) * 100) !important;
	height: 400px;
	/* bottom: 0px; */
	width: 100vw;
	left: 0;
	z-index: 999;
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	/* transform: translateZ(0); */
	svg{
		width: 250px;
		${mobile}{
			width: 170px;
		}
	}
`

const Text = styled(motion.div)`
	text-align: center;
	position: fixed;
	width: 100%;
	padding: 0 var(--m);
	bottom: var(--xl);
`

Transition.propTypes = {
	children: PropTypes.node,
}

export default Transition
